import { Avatar } from '@material-ui/core';
import { classes } from 'helpers';
import { useEffect, useRef } from 'react';
import { setCssVar } from 'utils/css';
import styles from './styles.module.scss';

export const BlockIcon = (props: Props) => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current && props.color) {
      setCssVar('--bg-color', props.color, ref.current);
    }
  }, [props.color]);

  return (
    <Avatar className={classes(styles['block-icon'], props.className || '')} innerRef={ref}>
      {props.icon}
    </Avatar>
  );
};

type Props = {
  icon: React.ReactNode;
  color?: string;
  className?: string;
};

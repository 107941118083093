import { Button, Icon, Menu, MenuItem, Typography } from '@material-ui/core';
import { FloatingBlock } from 'components/ReusableComponents/ContentContainer/FloatingBlock';
import { FirestoreTimestamp, keysOf } from 'helpers';
import { classes } from 'helpers/functions/format';
import React, { useEffect, useState } from 'react';
import { Event } from 'utils/db';
import { updateEvent } from 'utils/db/requests';
import styles from './styles.module.scss';

export const EventStatusBlock = (props: Props) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedStatus, setSelectedStatus] = useState(props.event.status);
  const handleEditClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
    setMenuOpen(true);
  };

  const handleMenuToggle = (isOpen: boolean) => () => setMenuOpen(isOpen);

  const handleEditChoice = (status: Status) => () => {
    setMenuOpen(false);
    setSelectedStatus(status);
  };

  const handleStatusConfirm = () => {
    if (selectedStatus === 'suspended') {
      const dateSuspended = FirestoreTimestamp.now();
      updateEvent(props.event.id, { status: selectedStatus, dateSuspended });
    } else {
      updateEvent(props.event.id, { status: selectedStatus });
    }
  };

  useEffect(() => {
    setSelectedStatus(props.event.status);
  }, [props.event.status]);

  return (
    <FloatingBlock
      //margin={15}
      label='Status'
      icon={<Icon>{eventStatusIcons[props.event.status]}</Icon>}
      className={classes(styles['status-block'], props.className || '')}
    >
      <Typography className={styles['status']}>
        {selectedStatus}
        <Button className={styles['edit-btn']} size='small' onClick={handleEditClick}>
          <Icon>edit</Icon>
        </Button>
        <Menu open={isMenuOpen} onClose={handleMenuToggle(false)} anchorEl={anchorEl}>
          {editStatuses.map((status, idx) => (
            <MenuItem className={styles['menu-item']} key={idx} onClick={handleEditChoice(status)}>
              {status}
            </MenuItem>
          ))}
        </Menu>
        {props.event.status !== selectedStatus && (
          <Button color='primary' size='small' variant='contained' onClick={handleStatusConfirm}>
            Confirm Status
          </Button>
        )}
      </Typography>
    </FloatingBlock>
  );
};

const eventStatusIcons: Record<Event['status'], string> = {
  live: 'check_circle_icon',
  complete: 'check_circle_icon',
  postponed: 'help_icon',
  suspended: 'error_icon',
  draft: 'border_color_icon',
};

type Status = keyof typeof eventStatusIcons;

const editStatuses = keysOf(eventStatusIcons);

type Props = {
  event: Event;
  className?: string;
};

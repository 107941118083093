import { Typography } from '@material-ui/core';
import { ParentProps } from 'utils/propTypes';
import styles from './page.module.scss';

export const Page = (props: Props) => {
  return (
    <div className={`${styles.page} ${props.className || ''}`}>
      <Typography className={styles.title} variant='h5'>
        {props.title}
      </Typography>
      {props.children}
    </div>
  );
};

type Props = {
  title: string;
  className?: string;
} & ParentProps;

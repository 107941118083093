import { db } from "services/firebase";
import firebase from "firebase/app";
import { useEffect, useState } from "react";

export const useDoc = <T>(docPath: string) => {
  const [docState, setDoc] = useState<DocState<T>>([undefined, true, null]);

  useEffect(() => {
    if (!isValidPath(docPath)) {
      console.warn("invalid doc path");
      return;
    }

    const unsubscribe = db.doc(docPath).onSnapshot(
      (snap) => {
        setDoc([snap.data() as T, false, null]);
      },
      (error) => {
        console.log({ error });
        setDoc([undefined, false, error]);
      }
    );
    return unsubscribe;
  }, [docPath]);

  return docState;
};

const isValidPath = (path: string) => {
  const segments = path.split("/").filter(Boolean).length;
  return segments % 2 === 0;
};

type DocState<T> = [
  Doc: T | undefined,
  IsLoading: boolean,
  Error: firebase.firestore.FirestoreError | null
];

import './MainLayout.scss';
import { ParentProps } from 'utils/propTypes';
import { Sidebar } from '../../components/Sidebar';
import { Topbar } from '../../components/Topbar';

export const MainLayout = (props: ParentProps) => {
  return (
    <div className='main-layout'>
      <Topbar />
      <Sidebar />
      <main className='main-content'>{props.children}</main>
    </div>
  );
};

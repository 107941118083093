import { ListItem } from 'components/ReusableComponents/ContentContainer/ListItem';
import { formatDate, formatCurrency } from 'helpers/functions';
import React from 'react';
import { useOrganizer, Organizer } from 'utils/db';
import { OrganizerOrderByOpts } from '../../Organizer/OrganizerItem';

export const PendingItem = (props: ItemProps) => {
  const [organizer] = useOrganizer(props.id);
  if (!organizer) return null;
  const orderByOpt = props.orderBy;

  return (
    <ListItem
      active={props.active}
      label={organizer.organizationName}
      super={getItemLabel(orderByOpt, organizer)} //Changed to date applied
      // super={`Joined on ${formatDate(organizer.dateApplied.toDate())}`} //Changed to date applied
      onClick={props.onClick}
    />
  );
};

const getItemLabel = (orderByOpt: OrganizerOrderByOpts, organizer: Organizer) => {
  switch (orderByOpt) {
    case 'organizationName':
      return organizer.organizationName;

    case 'dateApplied':
      return formatDate(organizer.dateApplied.toDate());

    case 'ticketsTotalAmountSold':
      return formatCurrency(organizer.ticketsTotalAmountSold);

    case 'totalProfileViews':
      return `${organizer.totalProfileViews} Views`;

    case 'totalFollowers':
      return `${organizer.totalFollowers} Followers`;

    default:
      return organizer.organizationName;
  }
};

type ItemProps = { id: string; active?: boolean; onClick: () => void; orderBy: OrganizerOrderByOpts };

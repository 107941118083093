import firebase from 'firebase/app';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from 'services/firebase';
import { ParentProps } from 'utils/propTypes';

const initialState: AuthState = {
  fbUser: null,
  isAdmin: false,
  isLoading: true,
  error: null,
};
const authContext = createContext(initialState);

export const useAuth = () => useContext(authContext);

export const AuthProvider = (props: ParentProps) => {
  const [authState, setAuthState] = useState(initialState);

  useEffect(() => {
    auth.onAuthStateChanged(
      fbUser => {
        if (fbUser) {
          fbUser.getIdTokenResult().then(t => {
            setAuthState({
              fbUser,
              isAdmin: t.claims.isAdmin ?? false,
              isLoading: false,
              error: null,
            });
          });
        } else {
          setAuthState({ ...initialState, isLoading: false });
        }
      },
      error => {
        setAuthState({ fbUser: null, isAdmin: false, isLoading: false, error });
      }
    );
  }, []);

  return <authContext.Provider value={authState}>{props.children}</authContext.Provider>;
};

type AuthState = {
  fbUser: firebase.User | null;
  isAdmin: boolean;
  isLoading: boolean;
  error: firebase.auth.Error | null;
};

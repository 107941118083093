import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@material-ui/core';
import { FloatingBlock } from 'components/ReusableComponents/ContentContainer/FloatingBlock';
import { formatDateAlt } from 'helpers';
import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'routes';
import { useOrganizer, usePendingOrgIDs } from 'utils/db';
import styles from './styles.module.scss';

export const PendingPreview = () => {
  const [[orgIDs]] = usePendingOrgIDs({ limit: 5 });

  return (
    <FloatingBlock label='Pending Approvals' icon={<FontAwesomeIcon icon={faUsers} />}>
      {[...orgIDs].map(id => (
        <OrgItem key={id} id={id} />
      ))}
    </FloatingBlock>
  );
};

const OrgItem = (props: { id: string }) => {
  const [organizer] = useOrganizer(props.id);
  if (!organizer) return null;

  return (
    <Link className={styles['org-item']} to={`${routes.PENDING}/${organizer.id}`}>
      <Typography>{organizer.organizationName}</Typography>
      <Typography>{formatDateAlt(organizer.dateApplied.toDate())}</Typography>
    </Link>
  );
};

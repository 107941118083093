import { Typography } from '@material-ui/core';
import { BlockIcon } from 'components/ReusableComponents/BlockIcon';
import React, { MutableRefObject, useEffect, useRef } from 'react';
import { setCssVar } from 'utils/css';
import { ParentProps } from 'utils/propTypes';
import styles from './styles.module.scss';

export const FloatingBlock = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.divRef) {
      props.divRef.current = ref.current;
    }
  }, [props.divRef]);

  useEffect(() => {
    const margin = props.margin ? props.margin + 'px' : '';
    setCssVar('--margin', margin, ref.current);
  }, [props.margin]);

  const classes = [props.className || '', styles['floating-block']].join(' ');

  return (
    <div ref={ref} className={classes}>
      <div>
        {props.label && <Typography className={styles['label']}>{props.label}</Typography>}
        {props.children}
      </div>
      {props.icon && <BlockIcon className={styles['icon']} icon={props.icon} color='#da6808' />}
    </div>
  );
};

type Props = {
  label?: string;
  margin?: number;
  className?: string;
  icon?: React.ReactNode;
  iconColor?: string;
  divRef?: MutableRefObject<HTMLElement | null>;
} & ParentProps;

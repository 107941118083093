import { useMemo } from 'react';
import { QueryOpts, useDoc, useDocIDs } from 'utils/firestore';
import { TicketHolder } from '../types/ticketHolder';

export const useAttendee = (id: string) => {
  return useDoc<TicketHolder>(`ticketHolders/${id}`);
};

export const useAttendeeIDs = (opts: QueryOpts<TicketHolder>) => {
  return useDocIDs('ticketHolders', opts);
};

export const useEventAttendees = (eventID: string) => {
  const filter = useMemo(() => {
    const filters: QueryOpts<TicketHolder>['where'] = [
      {
        fieldPath: 'eventId',
        filterOpt: '==',
        value: eventID,
      },
    ];
    return filters;
  }, [eventID]);
  const [[ids]] = useAttendeeIDs({ where: filter });
  return ids.size;
};

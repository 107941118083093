import { classes } from 'helpers';
import { useEffect, useRef } from 'react';
import { setCssVar } from 'utils/css';
import { ParentProps } from 'utils/propTypes';
import styles from './styles.module.scss';

export const Extendo = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      setCssVar('--height', `${ref.current.scrollHeight}px`, ref.current);
    }
  }, [props.children]);

  const extendedClass = props.extended ? styles['open'] : '';
  return (
    <div ref={ref} className={classes(styles['extendo'], extendedClass)}>
      {props.children}
    </div>
  );
};

type Props = {
  extended: boolean;
} & ParentProps;

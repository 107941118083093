import './Item.scss';
import { Icon } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';

export const Item = (props: Props) => {
  const isMaterialIcon = typeof props.icon === 'string';
  const location = useLocation();
  const activeClass = location.pathname.startsWith(props.path || ' ') ? 'active' : '';

  return (
    <Link className={'sidebar__item ' + activeClass} to={props.path || '#'}>
      <div className='sidebar__item__left'>
        <div className='icon'>{isMaterialIcon ? <Icon>{props.icon}</Icon> : props.icon}</div>
        <div className='name'>{props.name}</div>
      </div>
      {props.trailing && <div className='sidebar__item__right'>{props.trailing}</div>}
    </Link>
  );
};

type Props = {
  icon?: string | React.ReactElement;
  name: string;
  trailing?: React.ReactNode;
  path?: string;
  onClick?: React.DOMAttributes<HTMLElement>['onClick'];
};

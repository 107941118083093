import { Event } from 'utils/db';
import { QueryOpts, useDoc, useDocIDs } from 'utils/firestore';

export const useEvent = (id: string) => {
  return useDoc<Event>(`events/${id}`);
};

export const useEventIDs = (opts: QueryOpts<Event>) => {
  return useDocIDs('events', opts);
};

import { Button } from '@material-ui/core';
import { signOut } from 'utils/auth';
import { BrandBox } from './BrandBox';
import { MenuButton } from './MenuButton';
import './styles.scss';

export const Topbar = () => {
  return (
    <div className='topbar'>
      <div className='topbar__container'>
        <div className='topbar__left'>
          <BrandBox />
          <MenuButton />
        </div>
        <div className='topbar__right'>
          <Button variant='contained' onClick={signOut}>
            Sign Out
          </Button>
        </div>
      </div>
    </div>
  );
};

import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@material-ui/core';
import { FloatingBlock } from 'components/ReusableComponents/ContentContainer/FloatingBlock';
import { FirestoreTimestamp, formatDateAlt } from 'helpers';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'routes';
import { Event, useEvent, useEventIDs } from 'utils/db';
import { QueryOpts } from 'utils/firestore';
import styles from './styles.module.scss';

export const FlaggedEventsPreview = () => {
  const filter = useMemo(() => {
    const filters: QueryOpts<Event>['where'] = [
      {
        fieldPath: 'flagCount',
        filterOpt: '>',
        value: 0,
      },
    ];
    return filters;
  }, []);

  const [[eventIDs]] = useEventIDs({ limit: 5, where: filter, orderBy: 'flagCount', orderDir: 'desc' });

  return (
    <FloatingBlock label='Flagged Events' icon={<FontAwesomeIcon icon={faCalendarAlt} />}>
      {[...eventIDs].map(id => (
        <EventItem key={id} id={id} />
      ))}
    </FloatingBlock>
  );
};

const EventItem = (props: { id: string }) => {
  const [event] = useEvent(props.id);
  if (!event) return null;

  return (
    <Link className={styles['org-item']} to={`${routes.EVENTS}/${event.id}`}>
      <Typography>{event.title}</Typography>
      <Typography>{formatDateAlt(event.startTime.toDate())}</Typography>
    </Link>
  );
};

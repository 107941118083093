import { Icon, Typography } from '@material-ui/core';
import { FloatingBlock } from 'components/ReusableComponents/ContentContainer/FloatingBlock';
import { CssGrid, GridCell } from 'components/ReusableComponents/ContentContainer/Grid';
import { SocialMediaIcons } from 'components/ReusableComponents/ContentContainer/SocialMediaIcons';
import { formatDate, formatPhoneNum, organizerAddress } from 'helpers/functions';
import React from 'react';
import { useOrganizer } from 'utils/db';
import { OrganizerStatusBlock } from '../../Organizer/OrganizerDetail/OrganizerStatusBlock';
import styles from './styles.module.scss';

export const PendingApprovalDetail = (props: Props) => {
  const [pendingOrganizer] = useOrganizer(props.id);

  if (!pendingOrganizer) return null;

  return (
    <CssGrid className={styles['pending-detail']} cols={{ xs: 6 }} gap={10}>
      {/* ------------------------------------------------------------------------------------- Organizer Info Block ------------------------------------------------------------------------------------- */}
      <GridCell colSpan={{ xs: 6, lg: 4 }}>
        <FloatingBlock label='Organizer' icon={<Icon>account_box</Icon>}>
          <Typography>Name: {pendingOrganizer.organizationName}</Typography>
          {pendingOrganizer.church && <Typography>Church Name: {pendingOrganizer.church}</Typography>}
          <Typography>
            Phone: <a href={`tel:${pendingOrganizer.contact}`}>{formatPhoneNum(pendingOrganizer.contact)}</a>
          </Typography>
          <Typography>
            Email: <a href={`mailto:${pendingOrganizer.organizationEmail}`}>{pendingOrganizer.organizationEmail}</a>
          </Typography>
          <Typography>Address: {organizerAddress(pendingOrganizer)}</Typography>
          <Typography>Date Applied: {formatDate(pendingOrganizer.dateApplied.toDate())}</Typography>
          <SocialMediaIcons organizer={pendingOrganizer} />
        </FloatingBlock>
      </GridCell>
      {/* ------------------------------------------------------------------------------------- Mission Statement Block ------------------------------------------------------------------------------------- */}
      <GridCell colSpan={{ xs: 6, lg: 2 }} rowSpan={{ xs: 3 }}>
        <FloatingBlock label='Mission Statement' icon={<Icon>library_books_icon</Icon>}>
          <Typography>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque ea reiciendis explicabo, maxime nihil
            distinctio autem maiores quos optio eveniet ab dolor voluptates culpa at temporibus delectus.
          </Typography>
        </FloatingBlock>
      </GridCell>
      {/* ------------------------------------------------------------------------------------- Rep Info Block ------------------------------------------------------------------------------------- */}
      <GridCell colSpan={{ xs: 6, lg: 4 }}>
        <FloatingBlock label='Rep' icon={<Icon>face_icon</Icon>}>
          <Typography>Name: Meisha Guild</Typography>
          <Typography>
            Phone: <a href='tel:7534374847'>753-437-4847</a>
          </Typography>
          <Typography>
            Email: <a href='mailto:meisha.guild@gmail.com'>meisha.guild@gmail.com</a>
          </Typography>
        </FloatingBlock>
      </GridCell>
      {/* ------------------------------------------------------------------------------------- Package Block ------------------------------------------------------------------------------------- */}
      <GridCell colSpan={{ xs: 6, lg: 2 }}>
        <FloatingBlock label='Package' icon={<Icon>card_membership_icon</Icon>}>
          <Typography>{pendingOrganizer.package}</Typography>
        </FloatingBlock>
      </GridCell>
      {/* ------------------------------------------------------------------------------------- Status Block ------------------------------------------------------------------------------------- */}
      <GridCell colSpan={{ xs: 6, lg: 2 }}>
        <OrganizerStatusBlock organizer={pendingOrganizer} />
      </GridCell>
    </CssGrid>
  );
};

type Props = {
  id: string;
};

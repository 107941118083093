import { format, isThisYear } from 'date-fns';
import { Organizer } from 'utils/db';

export const formatPhoneNum = (num: string) => {
  // num = 7184980397
  const regex = /(\d{3})(\d{3})(\d+)/;
  const match = regex.exec(num); // [7184980397,718,498,0397]
  if (!match) return num;
  return match.slice(1).join('-'); // 718-498-0397
};

// Formatting Docs: https://date-fns.org/v2.17.0/docs/format

export const formatDateTime = (d: Date) => {
  return isThisYear(d) ? format(d, "MMM d',' p") : format(d, "MMM d',' y p");
};

export const formatDateTimeAlt = (d: Date) => {
  return isThisYear(d) ? format(d, 'M/d p') : format(d, 'M/d/yy p');
};

export const formatDate = (d: Date) => {
  return isThisYear(d) ? format(d, 'MMM d') : format(d, 'PP');
};

export const formatDateAlt = (d: Date) => {
  return isThisYear(d) ? format(d, 'M/d') : format(d, 'M/d/yy');
};

export const formatCurrency = (dollars: number) => {
  return new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD',
  }).format(dollars);
};

export const classes = (...classNames: (string | null | undefined)[]) => classNames.filter(Boolean).join(' ');

export const organizerAddress = (organizer: Organizer) => {
  return [organizer.workAddress, organizer.city, `, ${organizer.stateAbbreviation}`, organizer.zipCode].join(' ');
};

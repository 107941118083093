import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { FieldValue, formatDate } from 'helpers';
import React, { useMemo } from 'react';
import { useEventFlag, useEventFlagIDs, useUser, updateFlag, updateEvent, EventFlag } from 'utils/db';
import { QueryOpts } from 'utils/firestore';
import styles from './styles.module.scss';

export const EventFlagList = (props: Props) => {
  const filter = useMemo(() => {
    const filters: Filters = [
      {
        fieldPath: 'eventId',
        filterOpt: '==',
        value: props.eventID,
      },
      {
        fieldPath: 'status',
        filterOpt: '!=',
        value: 'dismissed',
      },
    ];
    return filters;
  }, [props.eventID]);

  const [[flaggedIDs]] = useEventFlagIDs({ limit: props.limit, where: filter });

  return (
    <Table className={styles['table']}>
      <TableHead>
        <TableRow>
          <TableCell>User</TableCell>
          <TableCell>User Email</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Description</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {[...flaggedIDs].map(id => (
          <FlagItem key={id} id={id} />
        ))}
      </TableBody>
    </Table>
  );
};

type Filters = QueryOpts<EventFlag>['where'];

type Props = {
  eventID: string;
  limit: number;
};

export const FlagItem = (props: ItemProps) => {
  const [flag] = useEventFlag(props.id);
  const [user] = useUser(flag?.reporterUserId || '.');
  if (!flag) return null;
  const handleDismiss = () => {
    updateFlag(props.id, { status: 'dismissed' });
    updateEvent(flag.eventId, { flagCount: FieldValue.increment(-1) as any });
  };

  return (
    <TableRow>
      <TableCell>{user?.fullName}</TableCell>
      <TableCell>
        <a href={`mailto:${user?.email}`}>{user?.email}</a>
      </TableCell>
      {flag.dateSubmitted && <TableCell>{formatDate(flag.dateSubmitted.toDate())}</TableCell>}
      <TableCell className={styles['description-cell']}>{flag.description}</TableCell>
      <TableCell>
        <Button size='small' color='secondary' variant='contained' onClick={handleDismiss}>
          Dismiss
        </Button>
      </TableCell>
    </TableRow>
  );
};

type ItemProps = {
  id: string;
  // limit: number;
};

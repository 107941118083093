import { Page } from 'components/Page';
import { CssGrid, GridCell } from 'components/ReusableComponents/ContentContainer/Grid';
import { OrderBy } from 'components/ReusableComponents/ContentContainer/OrderBy';
import { SearchBar } from 'components/ReusableComponents/ContentContainer/SearchBar';
import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { routes } from 'routes';
import { Organizer, usePendingOrgIDs } from 'utils/db';
import { useDynamicRef, useScrollBottom } from 'utils/hooks';
import { PendingApprovalDetail } from './PendingApprovalDetail';
import { PendingItem } from './PendingItem';
import styles from './styles.module.scss';
import { OrganizerOrderByOpts } from '../Organizer/OrganizerItem';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

export const PendingApprovalsPage = () => {
  const PAGE_SIZE = 10;
  const [limit, setLimit] = useState(PAGE_SIZE);
  const [searchTermInput, setSearchTermInput] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [orderBy, setOrderBy] = useState(orderByItems[0].id);
  const [orderDir, setOrderDir] = useState<'asc' | 'desc'>('asc');
  const history = useHistory();
  const match = useRouteMatch<URLParams>(`${routes.PENDING}/:orgID`);
  const orgID = match?.params.orgID;

  const [[orgIDs]] = usePendingOrgIDs({ limit, orderBy, orderDir }, searchTerm);

  const handleSelect = (id: string) => () => {
    history.push(`${routes.PENDING}/${id}`);
  };

  const scrollBottom = useScrollBottom();
  const orgIDsRef = useDynamicRef(orgIDs);

  const handleSearchChange = (value: string) => setSearchTermInput(value);

  const handleOrderDir = (e: React.ChangeEvent<HTMLInputElement>) => setOrderDir(e.target.value as any);

  return (
    <Page title='Pending Approvals'>
      <CssGrid cols={{ xs: 1, lg: '1fr 3fr' }} gap={15}>
        <GridCell className={styles['page-left']}>
          <SearchBar
            value={searchTermInput}
            onChange={handleSearchChange}
            placeholder='Search organizers'
            onClick={() => setSearchTerm(searchTermInput)}
            disabled={orderBy !== 'organizationName'}
          />
          <OrderBy items={orderByItems} value={orderBy} onChange={setOrderBy} />
          <RadioGroup row value={orderDir} onChange={handleOrderDir}>
            <FormControlLabel label={'Asc'} control={<Radio value='asc' />} />
            <FormControlLabel label={'Desc'} control={<Radio value='desc' />} />
          </RadioGroup>
          <div className={styles['list']}>
            {[...orgIDs].map(id => (
              <PendingItem key={id} id={id} active={id === orgID} onClick={handleSelect(id)} orderBy={orderBy} />
            ))}
          </div>
        </GridCell>
        <GridCell>{orgID && <PendingApprovalDetail id={orgID} />}</GridCell>
      </CssGrid>
    </Page>
  );
};

const orderByItems: { id: OrganizerOrderByOpts; label: string }[] = [
  { id: 'organizationName', label: 'Name' },
  { id: 'dateApplied', label: 'Date Joined' },
  { id: 'ticketsTotalAmountSold', label: 'Money Earned' },
  { id: 'totalProfileViews', label: 'Viewers' },
  { id: 'totalFollowers', label: 'Followers' },
];

type URLParams = {
  orgID: string;
};

import { RootRoute } from 'routes';
import { useAuth } from 'utils/auth';

function App() {
  const { isLoading } = useAuth();

  if (isLoading) return <div>Loading...</div>;

  return <RootRoute />;
}

export default App;

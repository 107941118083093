import { Icon, Typography } from '@material-ui/core';
import { FloatingBlock } from 'components/ReusableComponents/ContentContainer/FloatingBlock';
import { CssGrid, GridCell } from 'components/ReusableComponents/ContentContainer/Grid';
import { ImageCircle } from 'components/ReusableComponents/ContentContainer/ImageCircle';
import { formatCurrency, formatDate, formatDateTime } from 'helpers/functions';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'routes';
import { Event, useEvent, useOrganizer } from 'utils/db';
import { useEventAttendees } from 'utils/db/hooks/useAttendee';
import { EventFlagList } from '../EventFlagList';
import { EventStatusBlock } from './EventStatusBlock';

export const EventDetail = (props: Props) => {
  const PAGE_SIZE = 10;
  const [event] = useEvent(props.id);
  const [organizer] = useOrganizer(event?.organizerId || '.');
  const [limit, setLimit] = useState(PAGE_SIZE);

  if (!event) return null;

  return (
    <CssGrid cols={{ xs: 6 }} gap={10}>
      {/* ------------------------------------------------------------------------------------- Event Info Block ------------------------------------------------------------------------------------- */}
      <GridCell colSpan={{ xs: 6, lg: 4 }} rowSpan={{ lg: 2 }}>
        <FloatingBlock label='Event' icon={<Icon>today_icon</Icon>}>
          <Typography>Name: {event.title}</Typography>
          <Typography>
            Organizer: <Link to={`${routes.ORGANIZERS}/${organizer?.id}`}>{organizer?.organizationName}</Link>
          </Typography>
          <Typography>Location: {event.location ? event.location.fullAddress : 'No Address'}</Typography>
          <Typography>Event Time: {formatEventDuration(event)}</Typography>
          {event.status === 'suspended' && (
            <Typography>Suspended on: {formatDate(event.dateSuspended.toDate())}</Typography>
          )}
        </FloatingBlock>
      </GridCell>

      <GridCell colSpan={{ xs: 6, lg: 2 }} rowSpan={{ lg: 2 }}>
        <ImageCircle src={event.imgPath} />
      </GridCell>
      <GridCell colSpan={{ xs: 6, lg: 4 }} rowSpan={{ lg: 2 }}>
        {/* ------------------------------------------------------------------------------------- Description Block ------------------------------------------------------------------------------------- */}
        <FloatingBlock label='Description' icon={<Icon>library_books_icon</Icon>}>
          <Typography>{event.descriptionText}</Typography>
        </FloatingBlock>
      </GridCell>
      {/* ------------------------------------------------------------------------------------- Status Block ------------------------------------------------------------------------------------- */}
      <GridCell colSpan={{ xs: 6, lg: 2 }}>
        <EventStatusBlock event={event} />
      </GridCell>
      {/* ------------------------------------------------------------------------------------- Attendees Block ------------------------------------------------------------------------------------- */}
      <GridCell colSpan={{ xs: 6, lg: 2 }}>
        <FloatingBlock label='Attendees' icon={<Icon>group_icon</Icon>}>
          <Typography>
            <AttendeeCount eventID={event.id} />
          </Typography>
        </FloatingBlock>
        {/* ------------------------------------------------------------------------------------- Revenue Blocks ------------------------------------------------------------------------------------- */}
      </GridCell>
      <GridCell colSpan={{ xs: 6, md: 3 }}>
        <FloatingBlock label='Event Revenue' icon={<Icon>monetization_on_icon</Icon>} iconColor='green'>
          <Typography>{formatCurrency(event.totalOrganizerRevenue || 0)}</Typography>
        </FloatingBlock>
      </GridCell>
      <GridCell colSpan={{ xs: 6, md: 3 }}>
        <FloatingBlock label='Salt & Light Revenue' icon={<Icon>confirmation_number_icon</Icon>} iconColor='#d8b403'>
          <Typography>{formatCurrency(event.totalSaltLightFees || 0)}</Typography>
        </FloatingBlock>
      </GridCell>

      {/* ------------------------------------------------------------------------------------- Revenue Blocks ------------------------------------------------------------------------------------- */}

      <GridCell colSpan={{ xs: 6 }}>
        <FloatingBlock label='Flagged Details' icon={<Icon>flag_icon</Icon>}>
          {event.flagCount > 0 ? (
            <EventFlagList eventID={event.id} limit={limit} />
          ) : (
            <Typography>There are no flags on this event.</Typography>
          )}
        </FloatingBlock>
      </GridCell>
    </CssGrid>
  );
};

const AttendeeCount = (props: { eventID: string }) => {
  const count = useEventAttendees(props.eventID);
  return <>{count}</>;
};

const formatEventDuration = (event: Event) => {
  const start = event.startTime?.toDate();
  const end = event.endTime?.toDate();
  if (!start || !end) {
    return 'Missing Date';
  }
  return `${formatDateTime(start)} - ${formatDateTime(end)}`;
};

type Props = {
  id: string;
};

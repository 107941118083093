import { classes } from 'helpers/functions';
import { useEffect, useRef } from 'react';
import { setCssVar } from 'utils/css';
import { ParentProps } from 'utils/propTypes';
import styles from './styles.module.scss';

export const GridCell = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { colSpan, rowSpan, colOffset, rowOffset } = props;

  useEffect(() => {
    if (ref.current && colSpan) {
      Object.entries(colSpan).forEach(([size, val]) => {
        if (val) {
          setCssVar(`--col-span-${size}`, `span ${val}`, ref.current);
        }
      });
    }
  }, [colSpan, colSpan?.xs, colSpan?.sm, colSpan?.md, colSpan?.lg]);

  useEffect(() => {
    if (ref.current && rowSpan) {
      Object.entries(rowSpan).forEach(([size, val]) => {
        if (val) {
          setCssVar(`--row-span-${size}`, `span ${val}`, ref.current);
        }
      });
    }
  }, [rowSpan, rowSpan?.xs, rowSpan?.sm, rowSpan?.md, rowSpan?.lg]);

  useEffect(() => {
    if (ref.current && rowOffset) {
      Object.entries(rowOffset).forEach(([size, val]) => {
        if (val) {
          setCssVar(`--row-offset-${size}`, `${val}`, ref.current);
        }
      });
    }
  }, [rowOffset, rowOffset?.xs, rowOffset?.sm, rowOffset?.md, rowOffset?.lg]);

  useEffect(() => {
    if (ref.current && colOffset) {
      Object.entries(colOffset).forEach(([size, val]) => {
        if (val) {
          setCssVar(`--col-offset-${size}`, `${val}`, ref.current);
        }
      });
    }
  }, [colOffset, colOffset?.xs, colOffset?.sm, colOffset?.md, colOffset?.lg]);

  return (
    <div ref={ref} className={classes(styles['cell'], props.className || '')}>
      {props.children}
    </div>
  );
};

type Props = {
  className?: string;
  colSpan?: { xs?: number; sm?: number; md?: number; lg?: number };
  colOffset?: { xs?: number; sm?: number; md?: number; lg?: number };
  rowSpan?: { xs?: number; sm?: number; md?: number; lg?: number };
  rowOffset?: { xs?: number; sm?: number; md?: number; lg?: number };
} & ParentProps;

import { FirebaseAuth } from 'react-firebaseui';
import { auth } from 'services/firebase';
import firebase from 'firebase/app';

export const GoogleSignInBtn = () => {
  return <FirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />;
};

const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  callbacks: { signInSuccessWithAuthResult: () => false },
};

import React, { useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import { FloatingBlock } from '../FloatingBlock';
import { Typography } from '@material-ui/core';
import { classes } from 'helpers';

export const ListItem = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { onClick } = props;

  useEffect(() => {
    if (ref.current && onClick) {
      const el = ref.current;
      const listener = () => {
        onClick?.();
      };
      el.addEventListener('click', listener);
      return () => el.removeEventListener('click', listener);
    }
  }, [onClick]);

  const className = classes(styles['list-item'], props.active ? styles['active'] : '', props.className);

  return (
    <div ref={ref} className={className}>
      <FloatingBlock className={styles['block']} label={props.super}>
        <Typography>{props.label}</Typography>
      </FloatingBlock>
    </div>
  );
};

type Props = {
  super: string;
  label: string;
  active?: boolean;
  onClick?: () => void;
  className?: string;
};

import './styles.scss';
import logo from 'static/img/logo.svg';

export const BrandBox = () => {
  return (
    <div className='brand-box'>
      <img src={logo} alt='lighthouse logo' width='200' />
    </div>
  );
};

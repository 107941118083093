import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
} from '@material-ui/core';
import { Page } from 'components/Page';
import { Extendo } from 'components/ReusableComponents/ContentContainer/Extendo';
import { CssGrid, GridCell } from 'components/ReusableComponents/ContentContainer/Grid';
import { OrderBy } from 'components/ReusableComponents/ContentContainer/OrderBy';
import { SearchBar } from 'components/ReusableComponents/ContentContainer/SearchBar';
import { entriesOf } from 'helpers';
import React, { useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { routes } from 'routes';
import { Event, useEventIDs } from 'utils/db';
import { QueryOpts } from 'utils/firestore';
import { useScrollBottom } from 'utils/hooks';
import { EventDetail } from './EventDetail';
import { EventItem, EventOrderByOpts } from './EventItem';
import styles from './styles.module.scss';

export const EventsPage = () => {
  const PAGE_SIZE = 10;
  const [limit, setLimit] = useState(PAGE_SIZE);
  const [searchTermInput, setSearchTermInput] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [orderBy, setOrderBy] = useState(orderByItems[0].id);
  const [orderDir, setOrderDir] = useState<'asc' | 'desc'>('asc');
  const [filtersOpen, setFiltersOpen] = useState(false);
  const history = useHistory();
  const match = useRouteMatch<URLParams>(`${routes.EVENTS}/:eventID`);
  const eventID = match?.params.eventID;

  const [filterOpts, setFilterOpts] = useState({
    status: { enabled: false, value: eventStatuses[0] },
  });

  type FilterOpt = keyof typeof filterOpts;

  const handleFilterChange = (opt: FilterOpt, isValue?: boolean) => (e: React.ChangeEvent<any>) => {
    const option = { ...filterOpts[opt] };
    if (isValue) {
      option.value = e.target.value;
    } else {
      option.enabled = e.target.checked;
    }
    setFilterOpts(opts => ({ ...opts, [opt]: option }));
  };

  const filters = useMemo(() => {
    return entriesOf(filterOpts).reduce((f, [field, { enabled, value }]) => {
      if (enabled) {
        return [
          ...f,
          {
            fieldPath: field,
            filterOpt: '==',
            value,
          } as const,
        ];
      }
      return f;
    }, [] as Filters);
  }, [filterOpts.status]);

  const searchFilter = useMemo(
    () =>
      searchTerm && orderBy === 'title'
        ? {
            fieldName: 'title' as any,
            searchTerm: searchTerm,
          }
        : undefined,
    [searchTerm, orderBy]
  );
  const [[eventIDs]] = useEventIDs({ limit, orderBy, orderDir, search: searchFilter, where: filters });

  const scrollBottom = useScrollBottom();

  const handleSelect = (id: string) => () => {
    history.push(`${routes.EVENTS}/${id}`);
  };
  //   const orgIDsRef = useDynamicRef(orgIDs);
  const handleSearchChange = (value: string) => setSearchTermInput(value);

  return (
    <Page title='Events'>
      <CssGrid cols={{ xs: 1, lg: '1fr 3fr' }} gap={15}>
        <GridCell className={styles['page-left']}>
          <div>
            <SearchBar
              value={searchTermInput}
              onChange={handleSearchChange}
              placeholder='Search events'
              onClick={() => setSearchTerm(searchTermInput)}
              disabled={orderBy !== 'title'}
            />
            <OrderBy items={orderByItems} value={orderBy} onChange={setOrderBy} />
            <div>
              <FormGroup row>
                <RadioGroup row value={orderDir} onChange={e => setOrderDir(e.target.value as 'asc' | 'desc')}>
                  <FormControlLabel label='Asc' control={<Radio value='asc' />} />
                  <FormControlLabel label='Desc' control={<Radio value='desc' />} />
                </RadioGroup>
                <FormControlLabel
                  label='Filters'
                  control={<Switch checked={filtersOpen} onChange={() => setFiltersOpen(open => !open)} />}
                />
              </FormGroup>
              <Extendo extended={filtersOpen}>
                <div className={styles['filter-option']}>
                  <FormControl fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={filterOpts.status.value}
                      onChange={handleFilterChange('status', true)}
                      disabled={!filterOpts.status.enabled}
                    >
                      {eventStatuses.map(status => (
                        <MenuItem key={status} value={status}>
                          {status}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Checkbox
                    className='checkbox'
                    value={filterOpts.status.enabled}
                    onChange={handleFilterChange('status')}
                  />
                </div>
              </Extendo>
            </div>
          </div>
          <div className={styles['list']}>
            {[...eventIDs].map(id => (
              <EventItem key={id} id={id} active={id === eventID} onClick={handleSelect(id)} orderBy={orderBy} />
            ))}
          </div>
        </GridCell>
        <GridCell>{eventID && <EventDetail id={eventID} />}</GridCell>
      </CssGrid>
    </Page>
  );
};

const orderByItems: { id: EventOrderByOpts; label: string }[] = [
  { id: 'title', label: 'Name' },
  { id: 'startDate', label: 'Start Date' },
  { id: 'isFlagged', label: 'Flagged' },
  { id: 'views', label: 'Views' },
];

const eventStatuses: Event['status'][] = ['live', 'complete', 'postponed', 'suspended', 'draft'];

type URLParams = {
  eventID: string;
};

type Filters = Required<QueryOpts<Event>>['where'];

import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';

export const OrderBy = (props: Props) => {
  return (
    <FormControl fullWidth>
      <InputLabel>Order by</InputLabel>
      <Select label='Order by' value={props.value} onChange={e => props.onChange(e.target.value)}>
        {props.items.map(item => (
          <MenuItem key={item.id} value={item.id}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

type Props = {
  value: any;
  items: { label: any; id: string | number }[];
  onChange: (value: any) => void;
};

import { Page } from 'components/Page';
import { CssGrid, GridCell } from 'components/ReusableComponents/ContentContainer/Grid';
import React from 'react';
import { RevenueList } from './RevenueDetail/RevenueList';

export const RevenuePage = () => {
  return (
    <Page title='Revenue'>
      <CssGrid cols={{ xs: 1, md: '1fr 10fr 1fr' }} gap={10}>
        <GridCell colOffset={{ xs: 1, md: 2 }}>
          <RevenueList
            label={'Salt & Light Revenue'}
            colTitles={['Tickets', 'Total']}
            rows={[['$103,089', '$173,451']]}
          />
        </GridCell>
        <GridCell colOffset={{ xs: 1, md: 2 }}>
          <RevenueList
            label={'Top Earning Organizers'}
            colTitles={['Name', 'Revenue']}
            rows={[
              ['Merglobe Technologies', '$15,362'],
              ['COGEF', '$10,362'],
              ['KFT', '$7,362'],
            ]}
          />
        </GridCell>
        <GridCell colOffset={{ xs: 1, md: 2 }}>
          <RevenueList
            label={'Top Earning Events'}
            colTitles={['Name', 'Revenue']}
            rows={[
              ['God Is Love V', '$50,362'],
              ['Praising Him', '$23,362'],
              ['Christmas Contata', '$17,362'],
            ]}
          />
        </GridCell>
      </CssGrid>
    </Page>
  );
};

import { useMemo } from 'react';
import { Organizer } from 'utils/db';
import { QueryOpts, useDoc, useDocIDs } from 'utils/firestore';

export const useOrganizer = (id: string) => {
  return useDoc<Organizer>(`organizers/${id}`);
};

export const useOrganizerIDs = (opts: QueryOpts<Organizer>) => {
  return useDocIDs('organizers', opts);
};

export const usePendingOrgIDs = (opts: QueryOpts<Organizer>, searchTerm?: string) => {
  const newOpts = { ...opts };
  const whereFilter: WhereFilters = useMemo(
    () => [
      {
        fieldPath: 'status',
        filterOpt: '==',
        value: 'pending',
      },
      ...(opts.where || []),
    ],
    [opts.where]
  );

  newOpts.where = whereFilter;

  const searchFilter = useMemo(
    () =>
      searchTerm && opts.orderBy === 'organizationName'
        ? ({
            fieldName: 'organizationName',
            searchTerm: searchTerm,
          } as const)
        : undefined,
    [searchTerm, opts.orderBy]
  );

  newOpts.search = searchFilter;

  return useOrganizerIDs(newOpts);
};

type WhereFilters = QueryOpts<Organizer>['where'];

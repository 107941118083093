import { Topbar } from 'components/Topbar';
import { Redirect } from 'react-router-dom';
import { useAuth } from 'utils/auth';

export const Unauthorized = () => {
  const { fbUser } = useAuth();

  if (!fbUser) {
    return <Redirect to='/login' />;
  }

  return (
    <div className='unauthorized'>
      <Topbar />
      Unauthorized
    </div>
  );
};

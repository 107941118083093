import firebase from 'firebase/app';
import { useEffect, useRef, useState } from 'react';
import { db } from 'services/firebase';
import { FirestoreDocSnap, FirestoreQuery, QueryOpts } from '../types';

export const useDocIDs = <T>(collPath: string, opts: QueryOpts<T>) => {
  const [idsState, setIDsState] = useState<DocIDsState>([new Set(), true, null]);
  const [startDoc, setStart] = useState<FirestoreDocSnap>();
  const lastDocRef = useRef<FirestoreDocSnap>();

  /** Get next set of docs */
  const next = () => {
    // Signify we are starting from a new place
    setStart(lastDocRef.current);
  };

  useEffect(() => {
    // Signify retrieving begining of set
    setStart(undefined);
    setIDsState([new Set(), true, null]);
  }, [collPath, opts.orderBy, opts.orderDir, opts.where, opts.limit, opts.search]);

  useEffect(() => {
    let query: FirestoreQuery = db.collection(collPath);

    if (opts.where) {
      opts.where.forEach(opt => {
        query = query.where(String(opt.fieldPath), opt.filterOpt, opt.value);
      });
    }

    if (opts.orderBy) {
      query = query.orderBy(String(opts.orderBy), opts.orderDir);
    }

    //if (startDoc) {
    //  query = query.startAfter(startDoc);
    //}

    // Avoiding duplicating an orderBy
    if (opts.search) {
      if (opts.orderBy !== opts.search.fieldName) {
        query = query.orderBy(String(opts.search.fieldName));
        query = query.startAt(null, opts.search.searchTerm).endAt(null, opts.search.searchTerm + '~');
      } else {
        query = query.startAt(opts.search.searchTerm).endAt(opts.search.searchTerm + '~');
      }
    }

    if (opts.limit) {
      query = query.limit(opts.limit);
    }

    console.log('calling sub for: ' + collPath);
    const unsubscribe = query.onSnapshot(
      snap => {
        const added: string[] = [];
        const removed: string[] = [];

        console.log({ docs: snap.docs.map(d => d.data()) });

        snap.docChanges().forEach(docChange => {
          const docID = docChange.doc.id;

          console.log({
            docChange: { doc: docChange.doc.id, type: docChange.type },
          });

          // Only react to added/removed docs, not modified ones
          switch (docChange.type) {
            case 'added':
              added.push(docID);
              break;
            case 'removed':
              removed.push(docID);
              break;
          }
        });

        // Perform a single update with all added/removed doc IDs
        setIDsState(([oldIDs]) => {
          const newIDs = new Set(oldIDs);
          added.forEach(id => newIDs.add(id));
          removed.forEach(id => newIDs.delete(id));
          return [newIDs, false, null];
        });

        lastDocRef.current = snap.docs[snap.docs.length - 1];
      },
      error => {
        setIDsState([new Set(), false, error]);
        console.error({ error });
      }
    );

    return unsubscribe;
  }, [startDoc, collPath, opts.orderBy, opts.orderDir, opts.where, opts.limit, opts.search]);

  return [idsState, next] as const;
};

type DocIDsState = [DocIDs: Set<string>, IsLoading: boolean, Error: firebase.firestore.FirestoreError | null];

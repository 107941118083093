import { QueryOpts, useDoc, useDocIDs } from 'utils/firestore';
import { EventFlag } from '../types';

export const useEventFlag = (id: string) => {
  return useDoc<EventFlag>(`flags/${id}`);
};

export const useEventFlagIDs = (opts: QueryOpts<EventFlag>) => {
  return useDocIDs('flags', opts);
};

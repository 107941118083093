import { classes } from 'helpers/functions';
import { useEffect, useRef } from 'react';
import { setCssVar } from 'utils/css';
import { ParentProps } from 'utils/propTypes';
import styles from './styles.module.scss';

export const CssGrid = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { cols, gap } = props;

  useEffect(() => {
    if (ref.current && cols) {
      Object.entries(cols).forEach(([size, val]) => {
        if (val) {
          if (typeof val === 'number') {
            setCssVar(`--cols-${size}`, `repeat(${val}, 1fr)`, ref.current);
          } else {
            setCssVar(`--cols-${size}`, val, ref.current);
          }
        }
      });
    }
  }, [cols, cols?.xs, cols?.sm, cols?.md, cols?.lg]);

  useEffect(() => {
    if (ref.current && gap) {
      setCssVar(`--gap`, `${gap}px`, ref.current);
    }
  }, [gap]);

  return (
    <div ref={ref} className={classes(styles['grid'], props.className || '')}>
      {props.children}
    </div>
  );
};

type Props = {
  className?: string;
  cols?: { xs?: number | string; sm?: number | string; md?: number | string; lg?: number | string };
  gap?: number;
} & ParentProps;

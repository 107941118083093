import './Login.scss';
import { Button, Paper, TextField, Typography } from '@material-ui/core';
import { GoogleSignInBtn } from 'components/GoogleSignInBtn';
import { useState } from 'react';
import { signInWithEmailAndPassword, useAuth } from 'utils/auth';
import { Redirect } from 'react-router-dom';

export const Login = () => {
  const { fbUser } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errCode, setErrCode] = useState('');
  const [errType, errMsg] = getErrorMsg(errCode);

  if (fbUser) {
    return <Redirect to='/' />;
  }

  const signIn = async () => {
    const errCode = await signInWithEmailAndPassword(email, password);
    setErrCode(errCode || '');
  };

  return (
    <div className='login-page'>
      <div>
        <Paper className='login-card'>
          <Typography variant='h4'>Welcome to the Lighthouse</Typography>
          <Typography>Sign in to continue</Typography>
          <TextField label='Email' variant='standard' value={email} onChange={e => setEmail(e.target.value)} />
          <small className='errMsg'>{errType === 'email' ? errMsg : ''}</small>
          <TextField
            label='Password'
            variant='standard'
            type='password'
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <small className='errMsg'>{errType === 'password' ? errMsg : ''}</small>
          <Button
            type='submit'
            disabled={!email || !password}
            className='login-btn'
            variant='contained'
            onClick={signIn}
          >
            Sign In
          </Button>
          <Typography className='or'>OR</Typography>
          <GoogleSignInBtn />
        </Paper>
      </div>
    </div>
  );
};

const getErrorMsg = (errCode: string) => {
  switch (errCode) {
    case 'auth/invalid-email':
      return ['email', 'Invalid email'] as const;
    case 'auth/user-not-found':
      return ['email', 'User does not exist'] as const;
    case 'auth/wrong-password':
      return ['password', 'Incorrect password'] as const;
    default:
      return ['', ''] as const;
  }
};

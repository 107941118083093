import { Page } from 'components/Page';
import { CssGrid, GridCell } from 'components/ReusableComponents/ContentContainer/Grid';
import { PendingPreview } from './PendingPreview';
import { UpcomingEventsPreview } from './UpcomingEventsPreview';
import { FlaggedEventsPreview } from './FlaggedEventsPreview';

export const Dashboard = () => {
  return (
    <Page title='Dashboard'>
      <CssGrid cols={{ xs: 8 }} gap={10}>
        <GridCell colSpan={{ xs: 8, md: 2 }}>
          <PendingPreview />
        </GridCell>

        <GridCell colSpan={{ xs: 8, md: 2 }}>
          <UpcomingEventsPreview />
        </GridCell>
        <GridCell colSpan={{ xs: 8, md: 2 }}>
          <FlaggedEventsPreview />
        </GridCell>
      </CssGrid>
    </Page>
  );
};

import { ComponentProps } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { routes } from 'routes';
import { useAuth } from 'utils/auth';

export const PrivateRoute = (props: Props) => {
  const { fbUser, isAdmin } = useAuth();

  if (!fbUser) {
    return <Redirect to={routes.LOGIN} />;
  }

  if (!isAdmin) {
    return <Redirect to={routes.UNAUTHORIZED} />;
  }

  return <Route {...props}>{props.children}</Route>;
};

type Props = ComponentProps<typeof Route>;

import styles from './styles.module.scss';

export const ImageCircle = (props: Props) => {
  const classes = [styles['image-circle'], props.className || ''].join(' ');

  return (
    <div className={styles['container']}>
      <img className={classes} src={props.src} alt='profile' />
    </div>
  );
};

type Props = {
  src: string;
  className?: string;
};

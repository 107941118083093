import { db } from 'services/firebase';
import { Event, EventFlag, Organizer } from './types';

export const updateOrganizer = (organizerID: string, organizerData: OrganizerUpdate) => {
  return db.doc(`organizers/${organizerID}`).update(organizerData);
};

type OrganizerUpdate = Partial<Omit<Organizer, 'id'>>;

export const updateEvent = (eventID: string, eventData: EventUpdate) => {
  return db.doc(`events/${eventID}`).update(eventData);
};

type EventUpdate = Partial<Omit<Event, 'id'>>;

export const updateFlag = (flagID: string, flagData: FlagUpdate) => {
  return db.doc(`flags/${flagID}`).update(flagData);
};

type FlagUpdate = Partial<Omit<EventFlag, 'id'>>;

import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { formatDate } from 'helpers';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'routes';
import { useEvent, useEventIDs } from 'utils/db';

export const EventList = (props: Props) => {
  const filter = useMemo(
    () => ({
      where: [
        {
          fieldPath: 'organizerId' as any,
          filterOpt: '==' as any,
          value: props.organizerID,
        },
      ],
      limit: props.limit,
    }),
    [props.organizerID, props.limit]
  );

  const [[eventIDs]] = useEventIDs(filter);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Title</TableCell>
          <TableCell>Start Date</TableCell>
          <TableCell>End Date</TableCell>
          <TableCell>Location</TableCell>
          <TableCell>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {[...eventIDs].map(id => (
          <EventItem key={id} id={id} />
        ))}
      </TableBody>
    </Table>
  );
};

type Props = {
  organizerID: string;
  limit: number;
};

export const EventItem = (props: ItemProps) => {
  const [event] = useEvent(props.id);
  if (!event) return null;

  return (
    <TableRow>
      <TableCell>
        <Link to={`${routes.EVENTS}/${event.id}`}>{event.title}</Link>
      </TableCell>
      <TableCell>{event.startDate ? formatDate(event.startDate.toDate()) : 'No Start Date'}</TableCell>
      <TableCell>{event.endDate ? formatDate(event.endDate.toDate()) : 'No End Date'}</TableCell>
      <TableCell>{event.location ? event.location.fullAddress : 'No Address'}</TableCell>
      <TableCell>{event.status}</TableCell>
    </TableRow>
  );
};

type ItemProps = {
  id: string;
};

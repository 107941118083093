import React from 'react';
import { TextField, Button } from '@material-ui/core';
import './styles.scss';

export const SearchBar = (props: Props) => {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    props.onClick();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='search-bar-container'>
        <TextField
          fullWidth
          disabled={props.disabled}
          variant='filled'
          placeholder={props.placeholder}
          size='small'
          value={props.value}
          onChange={e => props.onChange(e.target.value)}
          InputProps={{
            endAdornment: (
              <Button color='primary' variant='contained' type='submit' disabled={props.disabled}>
                Search
              </Button>
            ),
          }}
        />
      </div>
    </form>
  );
};

interface Props {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  onClick: () => void;
  disabled?: boolean;
}

import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faDollarSign, faTachometerAlt, faUserCheck, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from '@material-ui/core';
import { routes } from 'routes';
import { usePendingOrgIDs } from 'utils/db';
import { Item } from './Item';
import './styles.scss';

export const Sidebar = () => {
  const havePending = useHavePending();

  return (
    <nav className='sidebar'>
      <Item path={routes.DASHBOARD} icon={<FontAwesomeIcon icon={faTachometerAlt} />} name='Dashboard' />
      <Item path={routes.ORGANIZERS} icon={<FontAwesomeIcon icon={faUsers} />} name='Organizers' />
      <Item path={routes.EVENTS} icon={<FontAwesomeIcon icon={faCalendarAlt} />} name='Events' />
      <Item
        path={routes.PENDING}
        icon={<FontAwesomeIcon icon={faUserCheck} />}
        name='Pending Approvals'
        trailing={<Badge color='primary' variant='dot' invisible={!havePending} />}
      />
      <Item path={routes.REVENUE} icon={<FontAwesomeIcon icon={faDollarSign} />} name='Revenue' />
    </nav>
  );
};

const useHavePending = () => {
  const [[orgIDs]] = usePendingOrgIDs({ limit: 1 });
  return orgIDs && orgIDs.size > 0;
};

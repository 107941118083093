import { PrivateRoute } from 'components/PrivateRoute';
import { Dashboard } from 'pages/Dashboard';
import { Login } from 'pages/Login';
import { OrganizersPage } from 'pages/Organizer';
import { PendingApprovalsPage } from 'pages/PendingApprovals';
import { EventsPage } from 'pages/Events';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Unauthorized } from 'Screens/Unauthorized';
import { MainLayout } from '../layouts/MainLayout';
import React from 'react';
import { RevenuePage } from 'pages/Revenue';

export const RootRoute = () => {
  return (
    <Switch>
      <Route path={routes.NOT_FOUND}>
        <div>404 - Page Not Found</div>
      </Route>

      <Route path={routes.LOGIN}>
        <Login />
      </Route>

      <Route path={routes.UNAUTHORIZED}>
        <Unauthorized />
      </Route>

      <PageRoutes />
    </Switch>
  );
};

const PageRoutes = () => {
  return (
    <MainLayout>
      <Switch>
        <Redirect exact from={routes.HOME} to={routes.DASHBOARD} />

        <PrivateRoute path={routes.DASHBOARD}>
          <Dashboard />
        </PrivateRoute>

        <PrivateRoute path={routes.ORGANIZERS}>
          <OrganizersPage />
        </PrivateRoute>

        <PrivateRoute path={routes.EVENTS}>
          <EventsPage />
        </PrivateRoute>

        <PrivateRoute path={routes.PENDING}>
          <PendingApprovalsPage />
        </PrivateRoute>

        <PrivateRoute path={routes.REVENUE}>
          <RevenuePage />
        </PrivateRoute>

        <Redirect to={routes.NOT_FOUND} />
      </Switch>
    </MainLayout>
  );
};

export const routes = {
  NOT_FOUND: '/404',
  UNAUTHORIZED: '/not_authorized',
  LOGIN: '/login',
  HOME: '/',
  DASHBOARD: '/dashboard',
  ORGANIZERS: '/organizers',
  EVENTS: '/events',
  PENDING: '/pending',
  REVENUE: '/revenue',
};

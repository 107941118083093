import { User } from 'utils/db';
import { QueryOpts, useDoc, useDocIDs } from 'utils/firestore';

export const useUser = (id: string) => {
  return useDoc<User>(`users/${id}`);
};

export const useUserID = (opts: QueryOpts<User>) => {
  return useDocIDs('users', opts);
};

import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { FloatingBlock } from 'components/ReusableComponents/ContentContainer/FloatingBlock';
import React, { useState } from 'react';
import styles from './styles.module.scss';

export const RevenueList = (props: Props) => {
  const [option, setOption] = useState('all');
  const [month, setMonth] = useState(months[0]);
  const [year, setYear] = useState(new Date().getFullYear());

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOption(event.target.value);
  };
  const handleSelectMonth = (event: React.ChangeEvent<{ value: unknown }>) => {
    setMonth(event.target.value as string);
  };
  const handleSelectYear = (event: React.ChangeEvent<{ value: unknown }>) => {
    setYear(Number(event.target.value));
  };

  const isMonthOpt = option === 'month';
  console.log({ isMonthOpt });
  return (
    <FloatingBlock label={props.label}>
      <div className={styles['select-options']}>
        {option === 'all' ? (
          <FormControl className={styles['select']}>
            <InputLabel></InputLabel>
            <Select disabled></Select>
          </FormControl>
        ) : (
          <FormControl className={styles['select']}>
            <InputLabel>{isMonthOpt ? 'Month' : 'Year'}</InputLabel>
            <Select onChange={isMonthOpt ? handleSelectMonth : handleSelectYear} value={isMonthOpt ? month : year}>
              {(isMonthOpt ? months : years).map(el => (
                <MenuItem key={el} value={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <FormControl>
          <RadioGroup row name='option' value={option} onChange={handleRadioChange}>
            <FormControlLabel label='Month' labelPlacement='start' value='month' control={<Radio size='small' />} />
            <FormControlLabel label='Year' labelPlacement='start' value='year' control={<Radio size='small' />} />
            <FormControlLabel label='All Time' labelPlacement='start' value='all' control={<Radio size='small' />} />
          </RadioGroup>
        </FormControl>
      </div>

      <Table>
        <TableHead>
          <TableRow>
            {props.colTitles.map(head => (
              <TableCell key={head}>{head}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((cont, index) => (
            <TableRow key={index}>
              {cont.map((info, index) => (
                <TableCell key={index}>
                  <Typography>{info}</Typography>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </FloatingBlock>
  );
};

type Props = {
  label: string;
  colTitles: string[];
  rows: string[][];
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'Deceber',
];
const years = ['2020', '2021', '2023'];

import { ListItem } from 'components/ReusableComponents/ContentContainer/ListItem';
import { formatDate } from 'helpers/functions/format';
import React from 'react';
import { Event, useEvent } from 'utils/db';

export const EventItem = (props: Props) => {
  const [event] = useEvent(props.id);
  if (!event) return null;
  const orderByOpt = props.orderBy;
  return (
    <ListItem
      active={props.active}
      label={event.title}
      super={getItemLabel(orderByOpt, event)} //Changed to date applied
      onClick={props.onClick}
    />
  );
};

const formatEventDuration = (event: Event) => {
  const start = event.startDate ? formatDate(event.startDate.toDate()) : 'No Start Date';
  const end = event.endDate ? formatDate(event.endDate.toDate()) : 'No End Date';
  return start === end ? start : `${start} - ${end}`;
};

type Props = { id: string; active?: boolean; orderBy: EventOrderByOpts; onClick: () => void };

const getItemLabel = (orderByOpt: EventOrderByOpts, event: Event) => {
  switch (orderByOpt) {
    case 'startDate':
      return event.startDate ? formatDate(event.startDate.toDate()) : 'No Start Date';

    case 'views':
      return `${event.views} Views`;

    case 'isFlagged':
      return `${event.flagCount || 0} Flags`;

    default:
      return formatEventDuration(event);
  }
};

export type EventOrderByOpts = 'title' | 'startDate' | 'isFlagged' | 'views';

import { Icon, Typography } from '@material-ui/core';
import { FloatingBlock } from 'components/ReusableComponents/ContentContainer/FloatingBlock';
import { CssGrid, GridCell } from 'components/ReusableComponents/ContentContainer/Grid';
import { ImageCircle } from 'components/ReusableComponents/ContentContainer/ImageCircle';
import { SocialMediaIcons } from 'components/ReusableComponents/ContentContainer/SocialMediaIcons';
import { formatCurrency, formatDate, formatPhoneNum, organizerAddress } from 'helpers/functions/format';
import React, { useState } from 'react';
import { useOrganizer } from 'utils/db';
import { EventList } from './EventList';
import { OrganizerStatusBlock } from './OrganizerStatusBlock';
import styles from './styles.module.scss';

export const OrganizerDetail = (props: Props) => {
  const PAGE_SIZE = 10;
  const [limit, setLimit] = useState(PAGE_SIZE);
  const [organizer] = useOrganizer(props.id);

  if (!organizer) {
    return null;
  }

  if (!organizer.id) {
    return (
      <div>
        <i>Missing Organizer ID</i>
      </div>
    );
  }

  return (
    <CssGrid className={styles['organizer-detail']} cols={{ xs: 6 }} gap={10}>
      {/*<div className={styles['header']}>*/}
      {/* ------------------------------------------------------------------------------------- Organizer Info Block ------------------------------------------------------------------------------------- */}
      <GridCell colSpan={{ xs: 6, md: 2 }} rowSpan={{ xs: 2 }}>
        <FloatingBlock label='Organizer' icon={<Icon>account_box</Icon>} iconColor={'#f00'}>
          <Typography>{organizer.organizationName}</Typography>
          <Typography>Address: {organizerAddress(organizer)}</Typography>
          <Typography>Rep Name: {`${organizer.repFirstName} ${organizer.repLastName}`}</Typography>
          <Typography>Joined: {formatDate(organizer.dateApplied.toDate())}</Typography>
          {organizer.status === 'approved' && (
            <Typography>Approved: {formatDate(organizer.dateApproved.toDate())}</Typography>
          )}
          {organizer.status === 'suspended' && (
            <Typography>Suspended on {formatDate(organizer.dateSuspended.toDate())}</Typography>
          )}

          <SocialMediaIcons organizer={organizer} />
        </FloatingBlock>
      </GridCell>
      {/* ------------------------------------------------------------------------------------- Contact Info Block ------------------------------------------------------------------------------------- */}
      <GridCell colSpan={{ xs: 6, md: 2 }} rowSpan={{ xs: 2 }}>
        <FloatingBlock label='Contact Info' icon={<Icon>contact_mail_icon</Icon>}>
          <Typography variant='subtitle2'>
            <b>Organizer</b>
          </Typography>
          <Typography>
            Phone: <a href={`tel:${organizer.contact}`}>{formatPhoneNum(organizer.contact)}</a>
          </Typography>
          <Typography>
            Email: <a href={`mailto:${organizer.organizationEmail}`}>{organizer.organizationEmail}</a>
          </Typography>
          <Typography variant='subtitle2'>
            <b>Representative</b>
          </Typography>
          <Typography>
            Phone: <a href={`tel:${organizer.repContact}`}>{formatPhoneNum(organizer.repContact)}</a>
          </Typography>
          <Typography>
            Email: <a href={`mailto:${organizer.repEmail}`}>{organizer.repEmail}</a>
          </Typography>
        </FloatingBlock>
      </GridCell>
      {/* ------------------------------------------------------------------------------------- Profile Pic ------------------------------------------------------------------------------------- */}
      <GridCell colSpan={{ xs: 6, md: 2 }} rowSpan={{ xs: 2 }}>
        {organizer.imgPath && <ImageCircle className={styles['profile-pic']} src={organizer.imgPath} />}
        {/*<div className={styles['pic-container']}>
        </div>*/}
      </GridCell>
      {/* ------------------------------------------------------------------------------------- Mission Statement ------------------------------------------------------------------------------------- */}
      <GridCell colSpan={{ xs: 6, md: 4 }} rowSpan={{ xs: 4, md: 2 }}>
        <FloatingBlock label='Mission Statement' icon={<Icon>library_books_icon</Icon>}>
          <Typography>{organizer.missionStatement}</Typography>
        </FloatingBlock>
      </GridCell>
      {/* ------------------------------------------------------------------------------------- Status Block ------------------------------------------------------------------------------------- */}
      <GridCell colSpan={{ xs: 6, md: 2 }}>
        <OrganizerStatusBlock organizer={organizer} />
      </GridCell>
      {/* ------------------------------------------------------------------------------------- Package Block ------------------------------------------------------------------------------------- */}
      <GridCell colSpan={{ xs: 6, md: 2 }}>
        <FloatingBlock label='Package' icon={<Icon>card_membership_icon</Icon>}>
          <Typography>{organizer.package}</Typography>
        </FloatingBlock>
      </GridCell>

      {/*</div>*/}
      {/* ------------------------------------------------------------------------------------- Stat Blocks ------------------------------------------------------------------------------------- */}
      <GridCell colSpan={{ xs: 6, md: 2 }}>
        <FloatingBlock label='Total Followers' icon={<Icon>group_icon</Icon>}>
          <Typography>{organizer.totalFollowers}</Typography>
        </FloatingBlock>
      </GridCell>
      <GridCell colSpan={{ xs: 6, md: 2 }}>
        <FloatingBlock label='Total Viewers' icon={<Icon>visibility_icon</Icon>}>
          <Typography>{organizer.totalProfileViews}</Typography>
        </FloatingBlock>
      </GridCell>
      <GridCell colSpan={{ xs: 6, md: 2 }}>
        <FloatingBlock label='Completed Events' icon={<Icon>today_icon</Icon>}>
          <Typography>{organizer.totalEventsCompleted}</Typography>
        </FloatingBlock>
      </GridCell>
      {/* ------------------------------------------------------------------------------------- Revenue Blocks ------------------------------------------------------------------------------------- */}
      <GridCell colSpan={{ xs: 6, md: 3 }}>
        <FloatingBlock label='Organizer Revenue' icon={<Icon>monetization_on_icon</Icon>} iconColor='green'>
          <Typography>{formatCurrency(organizer.totalOrganizerRevenue || 0)}</Typography>
        </FloatingBlock>
      </GridCell>
      <GridCell colSpan={{ xs: 6, md: 3 }}>
        <FloatingBlock label='Salt & Light Revenue' icon={<Icon>monetization_on_icon</Icon>} iconColor='#d8b403'>
          <Typography>{formatCurrency(organizer.totalSaltLightFees || 0)}</Typography>
        </FloatingBlock>
      </GridCell>

      {/* ------------------------------------------------------------------------------------- Events Block ------------------------------------------------------------------------------------- */}
      <GridCell colSpan={{ xs: 6 }}>
        <FloatingBlock label='Events' icon={<Icon>date_range_icon</Icon>}>
          <EventList limit={limit} organizerID={organizer.id} />
        </FloatingBlock>
      </GridCell>
    </CssGrid>
  );
};

type Props = {
  id: string;
};

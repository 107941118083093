import { Facebook, Instagram, Twitter, YouTube, Language } from '@material-ui/icons';
import { entriesOf } from 'helpers/functions';
import React from 'react';
import { Organizer } from 'utils/db';
import styles from './styles.module.scss';

export const SocialMediaIcons = (props: Props) => {
  if (!props.organizer.socialMedia) {
    return null;
  }
  const socialMedia = entriesOf(props.organizer.socialMedia)
    .filter(sm => sm[1])
    .sort();

  const websiteUrl = props.organizer.websiteURL;
  return (
    <div className={styles['social-icons']}>
      {websiteUrl && (
        <a target='_blank' rel='noopener noreferrer' href={formatLink(websiteUrl)}>
          <Language fontSize='large' color='action' />
        </a>
      )}
      {socialMedia.map(media => (
        <a key={media[0]} target='_blank' rel='noopener noreferrer' href={formatLink(media[1])}>
          {iconMap[media[0]]}
        </a>
      ))}
    </div>
  );
};

interface Props {
  organizer: Organizer;
}

const formatLink = (url: string) => {
  // Protocol must be included in url
  if (!url.toLowerCase().startsWith('http')) {
    return `http://${url}`;
  }
  return url;
};

const iconMap = {
  facebook: <Facebook fontSize='large' color='action' />,
  instagram: <Instagram fontSize='large' color='action' />,
  twitter: <Twitter fontSize='large' color='action' />,
  youtube: <YouTube fontSize='large' color='action' />,
};

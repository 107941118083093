import { auth } from 'services/firebase';
import firebase from 'firebase/app';

const provider = new firebase.auth.GoogleAuthProvider();

export const signInWithEmailAndPassword = (email: string, password: string) => {
  return auth
    .signInWithEmailAndPassword(email, password)
    .then(() => {
      return null;
    })
    .catch(err => {
      console.error(err);
      return err.code as string;
    });
};

export const signInWithGoogle = () => {
  auth
    .signInWithPopup(provider)
    .then(() => {
      return null;
    })
    .catch(err => {
      console.error(err);
    });
};

export const signOut = () => {
  auth.signOut();
};
